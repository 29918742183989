/* eslint-disable no-unused-vars */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-multi-str */
/* eslint-disable react/require-default-props */
/* eslint-disable array-callback-return */
/* eslint-disable react/button-has-type */
import React, { Component } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import ReactPaginate from 'react-paginate';
import { stringify } from 'qs';
import MenuBar from '../../../layouts/MenuBar';
import { API_URL } from '../../config/BaseUrl';
import RecordingsTable from './RecordingsTable';
import * as url from '../../../services/apis/calls/CallsUrl';
import Balance from './Balance';
import { getUsersInWorkgroup } from '../../../services/state-management/actions/workgroups/WorkgroupsActions';
import { fetchUser } from '../../../services/state-management/actions/auth/AuthActions';
import NotificationNIN from '../../../layouts/NotificationNIN';
import DownloadCallHistoryButton from './DownloadCallHistoryButton';
import moment from 'moment';
import DownloadAll from './DownloadAll';

class Calls extends Component {
  constructor(props) {
    super(props);

    var daysAgo = {};
    for (var i = 1; i <= 90; i++) {
      daysAgo[i] = moment()
        .subtract(i, 'days')
        .format('YYYY-MM-DD');
    }
    // console.log(daysAgo);
    const days = daysAgo[90];

    var todayDate = new Date().toISOString().slice(0, 10);
    // console.log(todayDate);

    this.state = {
      // totalcalls: [],
      loading: false,
      start: '',
      end: '',
      count: 0,
      pageNumber: 1,
      noOfRecords: 10,
      recordsPerPage: 10,
      callHistory: [],
      downloadData: [],
      downloadAll: [],
      fromDate: days,
      toDate: todayDate,
      userCardId: '',
      selectSearch: 'phone',
    };
  }

  componentDidMount() {
 
    this.getModifiedCalls(
      `${sessionStorage.getItem('cardid')}`,
      this.state.pageNumber,
      this.state.noOfRecords,
    );
    //for download all
    this.getModifiedCallss(
      `${sessionStorage.getItem('cardid')}`,
      this.state.pageNumber,
      this.state.noOfRecords,
    );
    this.getAllModifiedCallsWithDate(
      `${sessionStorage.getItem('cardid')}`,
      this.state.fromDate,
      this.state.toDate,
    );
    //for download all
    this.getModifiedCallsWithDates(
      `${sessionStorage.getItem('cardid')}`,
      this.state.fromDate,
      this.state.toDate,
    );

    this.props.getUsersInWorkgroup();
    this.props.fetchUser();
  }

  onChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  onSearchChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  getModifiedCalls = async (cardid, pageNumber) => {
    this.setState({
      loading: true,
    });

    try {
      const params = new URLSearchParams();
      params.append('action', 'wificallcallhistory');
      params.append('cardid', cardid);
      params.append('from', `${this.state.fromDate} 00:00:00`);
      params.append('to', `${this.state.toDate} 23:59:59`);
      params.append('pageno', pageNumber);
      params.append('no_of_records_per_page', this.state.recordsPerPage);
      const res = await axios.post(`${url.GENIATECH_URL}`, params);
      if (res.data.status) {
        this.setState({
          pageNumber: res.data.current_page,
          noOfRecords: res.data.total_records,
          callHistory: res.data.records,
          count: res.data.records.length,
          loading: false,
        });
      }
    } catch (e) {
      if (e) {
        this.setState({
          loading: false,
        });
      }
    }
  };

  getModifiedCallss = async (cardid, pageNumber) => {
    // this.setState({
    //   loading: true,
    // });

    try {
      const params = new URLSearchParams();
      params.append('action', 'wificallcallhistory');
      params.append('cardid', cardid);
      params.append('from', `${this.state.fromDate} 00:00:00`);
      params.append('to', `${this.state.toDate} 23:59:59`);
      params.append('pageno', pageNumber);
      params.append('no_of_records_per_page', '10000');
      const res = await axios.post(`${url.GENIATECH_URL}`, params);
      if (res.data.status) {
        this.setState({
          // pageNumber: res.data.current_page,
          // noOfRecords: res.data.total_records,
          downloadAll: res.data.records,
          // count: res.data.records.length,
          // loading: false,
        });
      }
    } catch (e) {
      if (e) {
        this.setState({
          loading: false,
        });
      }
    }
  };

  getModifiedCallsWithDate = async (
    cardid,
    pageNumber,
    noOfRecords,
    recordsPerPage,
    fromDate,
    toDate,
  ) => {
    this.setState({
      loading: true,
    });
    try {
      const params = new URLSearchParams();
      params.append('action', 'wificallcallhistory');
      params.append('cardid', cardid);
      params.append('from', `${fromDate} 00:00:00`);
      params.append('to', `${toDate} 23:59:59`);
      params.append('pageno', pageNumber);
      params.append('no_of_records_per_page', recordsPerPage);
      const res = await axios.post(`${url.GENIATECH_URL}`, params);
      if (res.data.status) {
        this.setState({
          pageNumber: res.data.current_page,
          noOfRecords: res.data.total_records,
          callHistory: res.data.records,
          count: res.data.records.length,
          loading: false,
        });
      }
    } catch (e) {
      if (e) {
        this.setState({
          loading: false,
        });
      }
    }
  };

  getAllModifiedCallsWithDate = async (cardid, fromDate, toDate) => {
    this.setState({
      loading: true,
    });
    try {
      const params = new URLSearchParams();
      params.append('action', 'wificallcallhistory');
      params.append('cardid', cardid);
      params.append('from', `${fromDate} 00:00:00`);
      params.append('to', `${toDate} 23:59:59`);
      params.append('sec', 'unrestricted');
      const res = await axios.post(`${url.GENIATECH_URL}`, params);
      if (res.data.status) {
        this.setState({
          downloadData: res.data.records,
        });
      }
    } catch (e) {}
  };
  //query for download all
  getModifiedCallsWithDates = async (
    cardid,
    pageNumber,
    noOfRecords,
    recordsPerPage,
    fromDate,
    toDate,
  ) => {
    // this.setState({
    //   loading: true,
    // });
    try {
      const params = new URLSearchParams();
      params.append('action', 'wificallcallhistory');
      params.append('cardid', cardid);
      params.append('from', `${fromDate} 00:00:00`);
      params.append('to', `${toDate} 23:59:59`);
      params.append('pageno', pageNumber);
      params.append('no_of_records_per_page', '10000');
      const res = await axios.post(`${url.GENIATECH_URL}`, params);
      if (res.data.status) {
        this.setState({
          // pageNumber: res.data.current_page,
          // noOfRecords: res.data.total_records,
          downloadAll: res.data.records,
          // count: res.data.records.length,
          // loading: false,
        });
      }
    } catch (e) {
      if (e) {
        this.setState({
          loading: false,
        });
      }
    }
  };

  getCalls = async () => {
    this.setState({
      loading: true,
    });
    try {
      const res = await axios.get(`${url.GET_CALLS_URL}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      });
      if (res.data.result) {
        this.setState({
          // totalcalls: res.data.result,
          loading: false,
          count: res.data.result.length,
        });
      } else {
        this.setState({
          loading: false,
        });
      }
    } catch (e) {
      if (e) {
        this.setState({
          loading: false,
        });
      }
    }
  };

  searchWithDateModified = async (e) => {
    e.preventDefault();
    this.getModifiedCallsWithDate(
      `${sessionStorage.getItem('cardid')}`,
      this.state.pageNumber,
      this.state.noOfRecords,
      this.state.recordsPerPage,
      this.state.fromDate,
      this.state.toDate,
    );

    this.getModifiedCallsWithDates(
      `${sessionStorage.getItem('cardid')}`,
      this.state.pageNumber,
      this.state.noOfRecords,
      this.state.recordsPerPage,
      this.state.fromDate,
      this.state.toDate,
    );

    this.getAllModifiedCallsWithDate(
      `${sessionStorage.getItem('cardid')}`,
      this.state.fromDate,
      this.state.toDate,
    );
  };

  handlePageChange = (pageNumber) => {
    const { start, end } = this.state;
    this.setState({
      loading: true,
    });
    const selected = pageNumber.selected + 1;

    const pageUrl = `${API_URL}/wificall/recentCalls/${selected}?from=${start}&to=${end}`;

    axios
      .get(pageUrl, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      })
      .then((res) => {
        if (res.data.status === true) {
          this.setState({
            // filtered: res.data.result,
            loading: false,
          });
        }
      });
  };

  getEachUserRecording = async (e) => {
    const { value } = e.target;
    this.setState({
      // userCardId: value,
    });
    if (value) {
      this.setState({
        loading: true,
        // calls: 0,
      });
      try {
        const response = await axios.get(`${url.GET_CALLS_URL}${value}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
          },
        });
        if (response.data.status === true) {
          this.setState({
            // totalcalls: response.data.result,
            // filtered: response.data.result,
            loading: false,
            count: response.data.result.length,
            // calls: response.data.no_of_records,
          });
        }
      } catch (error) {
        if (error) {
          this.setState({
            loading: false,
          });
        }
      }
    }
  };

  // eslint-disable-next-line consistent-return
  checkUserPlanToViewRecordings = () => {
    const { users, user } = this.props;
    if (user.plan_id > 2 && user.plan_id < 11) {
      return (
        <div className="d-flex">
          <small className="mr-2 mt-2 d-none d-lg-block">Search By:</small>
          <span className="d-none d-lg-block">
            <select
              name="userid"
              id=""
              className="form-control copy-font mr-3 w-100"
              onChange={this.getEachUserRecording}
            >
              <option>Search For User Call History</option>
              {/* eslint-disable-next-line react/prop-types */}
              {users.map((use) => {
                return (
                  <option value={use.id}>
                    {`${use.firstname} ${use.lastname}`}
                  </option>
                );
              })}
            </select>
          </span>
        </div>
      );
    }
    return null;
  };

  // eslint-disable-next-line react/sort-comp
  submitButton() {
    return (
      <button
        className="btn btn-blue text-white"
        style={{ backgroundColor: '#35BEAA' }}
      >
        Submit
      </button>
    );
  }

  searchPhone(e) {
    const keyword = e.target.value;
    const { callHistory } = this.state;

    if (keyword !== '') {
      // eslint-disable-next-line consistent-return
      const list = callHistory.filter((call) => {
        if (call.src.indexOf(keyword) !== -1) {
          return call;
        }
        if (call.calledstation.indexOf(keyword) !== -1) {
          return call;
        }
      });
      this.setState({
        callHistory: list,
        keyword: e.target.value,
      });
    } else {
      this.setState({
        // callHistory: callHistory,
        keyword,
      });
    }
  }

  modifiedPageClick = (data) => {
    const selected = data.selected + 1;
    this.setState({
      loading: true,
    });
    this.getModifiedCalls(
      `${sessionStorage.getItem('cardid')}`,
      selected,
      this.state.noOfRecords,
    );
  };

  searchByPhoneNumber = async () => {
    const { keyword, selectSearch, fromDate, toDate } = this.state;
    this.setState({ loading: true });
    let data;

    if (selectSearch === 'phone') {
      data = {
        cardid: `${sessionStorage.getItem('cardid')}`,
        action: 'searchwificallcallhistory',
        phonenumber: keyword,
        from: `${fromDate} 00:00:00`,
        to: `${toDate} 23:59:59`,
      };
    } else {
      data = {
        cardid: `${sessionStorage.getItem('cardid')}`,
        action: 'searchwificallcallhistory',
        src: keyword,
        from: `${fromDate} 00:00:00`,
        to: `${toDate} 23:59:59`,
      };
    }

    try {
      const response = await axios.post(
        `${url.GENIATECH_URL}`,
        stringify(data),
      );
      if (response.data) {
        this.setState({
          callHistory: response.data.records,
          count: 0,
          loading: false,
        });
      }
    } catch (e) {
      this.setState({ loading: false });
    }
  };

  render() {
    const {
      loading,
      keyword,
      start,
      recordsPerPage,
      callHistory,
      downloadData,
      downloadAll,
    } = this.state;

    return (
      <div className="body">
        <div className="container-fluid">
          <div className="row">
            <NotificationNIN />
          </div>
          <div className="col-lg-12 dashboard-image mt-4 d-none d-lg-block">
            <img
              src="https://res.cloudinary.com/teewhy/image/upload/v1545234921/wifi-without-dropdown.fw.png"
              alt="logo"
              width="12%"
              height="15%"
            />
          </div>
          <div className="row">
            <MenuBar />
          </div>
          <div className="container-fluid" id="resize-fluid">
            <div className="row wallboard-table">
              <div className="col-lg-12 mt-4 d-none d-md-block d-lg-block">
                <div className="row p-0">
                  <div className="col-12 d-flex flex-wrap-reverse">
                    <div className="form-group ml-2 mr-4 d-none d-lg-block">
                      <div className="d-flex">
                        <small className="mr-2 mt-2 d-none d-lg-block">
                          Search By:
                        </small>
                        <span className="d-none d-lg-block">
                          <div className="d-flex">
                            <select
                              className="form-control copy-font mr-3"
                              style={{
                                fontSize: '12px',
                                marginLeft: '5px',
                              }}
                              name="selectSearch"
                              onChange={this.onSearchChange}
                            >
                              <option value="phone">phone number</option>
                              <option value="src"> src</option>
                            </select>
                            <input
                              type="text"
                              className="form-control copy-font mr-3 w-100"
                              placeholder="Search"
                              value={keyword}
                              name="keyword"
                              onChange={this.onSearchChange}
                            />
                          </div>
                        </span>

                        <button
                          className="btn btn-blue text-white"
                          style={{ backgroundColor: '#35BEAA' }}
                          onClick={this.searchByPhoneNumber}
                          disabled={!keyword}
                        >
                          Submit
                        </button>
                      </div>
                      <br />

                      {this.checkUserPlanToViewRecordings()}
                    </div>

                    <div className="p-0 mr-auto">
                      <form
                        className="col-lg-12 form-group d-flex mr-2"
                        onSubmit={this.searchWithDateModified}
                      >
                        <small className="mt-2 p-0 mr-2">
                          Show calls from:
                        </small>
                        <div className="mr-3">
                          <input
                            type="date"
                            className="form-control copy-font w-100"
                            name="fromDate"
                            value={this.state.fromDate}
                            onChange={this.onChange}
                          />
                        </div>
                        <small className="mr-2 mt-2">To:</small>
                        <div className="mr-3">
                          <input
                            type="date"
                            className="form-control copy-font w-100"
                            name="toDate"
                            value={this.state.toDate}
                            onChange={this.onChange}
                            id="test"
                          />
                        </div>
                        <small className="mr-2 mt-2">Current Page:</small>
                        <div className="mr-3 mt-2">
                          <small> {this.state.pageNumber}</small>
                        </div>

                        <small className="mr-2 mt-2">Per Page:</small>
                        <div className="mr-3">
                          <select
                            className="form-control copy-font w-100"
                            value={recordsPerPage}
                            onChange={this.onChange}
                            name="recordsPerPage"
                          >
                            <option value="10">10</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="150">150</option>
                            <option value="200">200</option>
                          </select>
                        </div>
                        {/* {this.state.toDate} */}
                        <div>{this.submitButton()}</div>
                      </form>
                    </div>

                    {downloadData.length > 0 && callHistory.length > 0 && (
                      <DownloadCallHistoryButton downloadData={callHistory} />
                    )}

                    {downloadAll.length > 0 && (
                      <DownloadAll downloadAll={downloadAll} />
                    )}

                    <Balance />
                  </div>
                </div>
              </div>

              <div className="col-lg-12">
                <div className="card o-income card-body ml-2">
                  <div style={{ overflowX: 'auto' }}>
                    <table className="table copy-font">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>From</th>
                          <th>To</th>
                          <th>Date</th>
                          <th>Duration</th>
                          <th>Call Status </th>
                          <th className="text-center">Download (Individual)</th>
                          <th className="text-center">
                            Download (Business Users)
                          </th>
                        </tr>
                      </thead>
                      {this.state.callHistory.length > 0 && (
                        <RecordingsTable
                          recordings={this.state.callHistory}
                          load={loading}
                        />
                      )}
                    </table>
                  </div>
                </div>
                <nav aria-label="Page navigation example" className="ml-2 mt-4">
                  {this.state.callHistory.length >= 10 && this.state.count > 0 && (
                    <ul className="font15 pagination">
                      <span className="mr-2 mt-2" style={{ color: '#00a7ff' }}>
                        Per Page:
                      </span>
                      <div className="mr-3">
                        <select
                          className="form-control border font15 w-100"
                          style={{ color: '#00a7ff', borderRadius: 0 }}
                          value={recordsPerPage}
                          onChange={this.onChange}
                          name="recordsPerPage"
                        >
                          <option value="10">10</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                          <option value="150">150</option>
                          <option value="200">200</option>
                        </select>
                      </div>

                      <ReactPaginate
                        nextLinkClassName="page-link"
                        previousLinkClassName="page-link"
                        previousLabel="Previous"
                        pageLinkClassName="page-link"
                        nextLabel="Next"
                        breakLabel="..."
                        breakClassName="break-me"
                        pageCount={this.state.noOfRecords}
                        marginPagesDisplayed={5}
                        pageRangeDisplayed={5}
                        containerClassName="pagination"
                        onPageChange={this.modifiedPageClick}
                      />
                    </ul>
                  )}
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  users: state.users.users,
  user: state.auth.auth,
});

Calls.propTypes = {
  getUsersInWorkgroup: PropTypes.func,
  users: PropTypes.shape,
  user: PropTypes.shape,
  fetchUser: PropTypes.func,
};
export default connect(
  mapStateToProps,
  { getUsersInWorkgroup, fetchUser },
)(Calls);
